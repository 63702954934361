import ButtonAppBar from "./AppBar";
import React, {useEffect, useState} from "react";
import './App.css';
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {PersonOutline} from "@material-ui/icons";


export default function TopVotingSurvey(props) {

    const [message, setMessage] = useState('');
    const [owner, setOwner] = useState('');
    const [survey, setSurvey] = useState('');
    const [response, setResponse] = useState('');
    const [files, setFiles] = useState([]);

    const [topVoting, setTopVoting] = useState('');

    function handleSubmit(event) {
        event.preventDefault();
        var item = {
            link: window.location.href,
            response: response
        };

        props.api.saveTopVote(item).then(response => {
            if (!response.ok) {
                setMessage("Es ist ein Fehler aufgetreten");
            } else {
                setMessage("Antwort gespeichert");
            }
        });


    }

    useEffect(() => {
        setMessage();
        props.api.getTopVotingAttendee(window.location.href).then(response => {
            if (!response.ok) {
                setMessage("Es ist ein Fehler aufgetreten");
            } else {
                response.json().then(res => {
                    setTopVoting(res);
                    if (res.response > -1) {
                        setMessage("Sie haben bereits geantwortet ");
                    }
                });
            }
        })
    }, []);

    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container style={{border: "2px solid orange"}}>{message}</Container>
            <br/><br/>
            <Paper>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h5" color="secondary">{topVoting.voteTitle}</Typography>
                        {topVoting.voteDescription}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5"
                                    color="secondary"><PersonOutline/>{topVoting.firstname} {topVoting.lastname}</Typography>
                        {topVoting.trustee && topVoting.trustee.firstName != "" &&
                            <Typography>Vollmacht
                                für {topVoting.trustee.firstName} {topVoting.trustee.lastName}</Typography>}
                    </Grid>
                </Grid>

                <br/><br/>
                <Grid container>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Antwort:</FormLabel>
                                <RadioGroup defaultValue="female" aria-label="Antwort" name="customized-radios"
                                            value={response}
                                            onChange={e => setResponse(e.target.value)}>
                                    <FormControlLabel value="1" control={<Radio/>} label="Ja"
                                                      disabled={message}/>
                                    <FormControlLabel value="2" control={<Radio/>} label="Nein"
                                                      disabled={message}/>
                                    <FormControlLabel value="0" control={<Radio/>} label="Enthaltung"
                                                      disabled={message}/>
                                </RadioGroup>
                                <Button variant="contained" color="primary" type="submit"
                                        disabled={message}>Speichern</Button>
                            </FormControl>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}