import React from 'react';
import {FormLabel, Grid} from "@material-ui/core";
import '../App.css';
import TopModal from "./TopModal";
import TopVotingModal from "./TopVotingModal";

export default function TopRow(props) {


    const top = props.top;

    return (<Grid container className={"topRow"}>
        <Grid item xs={12}><FormLabel>TOP {props.index + 1} {top.type && "(Beschluss)"}</FormLabel></Grid>
        <Grid item xs={4}>
            {top.title}
        </Grid>
        <Grid item xs={7}>
            {top.description.substring(0, 100)}...
        </Grid>
        <Grid item xs={1}>
            {!top.type &&
                <TopModal updateTop={props.updateTop} top={top} index={props.index} deleteTop={props.deleteTop}/>}
            {top.type &&
                <TopVotingModal updateTop={props.updateTop} top={top} index={props.index} api={props.api}
                                deleteTop={props.deleteTop}/>}
        </Grid>
    </Grid>)
}