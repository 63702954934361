import React, {useEffect, useState} from 'react';
import ButtonAppBar from "../AppBar";
import {Card, CardActionArea, CardContent, Container, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AddCircleOutline, TextFields} from "@material-ui/icons";
import {Link} from "react-router-dom";
import '../App.css';
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));


export default function Buy(props) {

    let order;

    const [prizeCircRes, setPrizeCircRes] = useState(0.0);
    const [prizeMeeting, setPrizeMeeting] = useState(0, 0);

    const [amountCircRes, setAmountCircRes] = useState(0);
    const [amountMeeting, setAmountMeeting] = useState(0);

    const [errorMessage, setErrorMessage] = useState("");

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        props.api.getPrizes().then(res => res.json())
            .then(response => {
                setPrizeCircRes(response.prizeCircRes);
                setPrizeMeeting(response.prizeMeeting);
            })
    });

    const classes = useStyles();
    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container>
                <Typography color="error">{errorMessage}</Typography>
                <Paper>
                    <Typography variant="h5" color="secondary">Versammlung / Umlaufbeschluss kaufen</Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <TextField type="number" value={amountCircRes}
                                               onInput={e => setAmountCircRes(e.target.value)}></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    x Umlaufbeschluss ({prizeCircRes}€)
                                </Grid>
                                <Grid item xs={4} container justifyContent="flex-end">
                                    {new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format(amountCircRes * prizeCircRes)}
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField type="number" value={amountMeeting}
                                               onInput={e => setAmountMeeting(e.target.value)}></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    x Versammlung ({prizeMeeting}€)
                                </Grid>
                                <Grid item xs={4} container justifyContent="flex-end">
                                    {new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format(amountMeeting * prizeMeeting)}
                                </Grid>

                                <Grid item xs={12} container justifyContent="flex-end"><b>
                                    Summe: {new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                }).format(amountCircRes * prizeCircRes + amountMeeting * prizeMeeting)}
                                </b>
                                </Grid>

                                {success &&
                                    <Container>
                                        <Grid item xs={1}/>
                                        <Grid item xs={10} container justifyContent="center"
                                              className={"successMessage"}>
                                            <Typography>Zahlung erfolgreich!</Typography>
                                        </Grid>
                                        <Grid item xs={1}/>
                                        <Grid item xs={1}/>
                                        <Grid item xs={10} container justifyContent="center">
                                            <Link to="/">Zurück zur Objektübersicht</Link>
                                        </Grid>
                                        <Grid item xs={1}/>
                                    </Container>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <PayPalScriptProvider
                                options={{
                                    "client-id": "AcUooKq7W17-vN2M0pV_BoSKJpNvVd5XHfnCaOURZPNnDQ-g5zta0dNpHfUvE9IZQo2DZ7zhwwMJY6to",
                                    "currency": "EUR",
                                }}>
                                <PayPalButtons
                                    disabled={(amountCircRes <= 0 && amountMeeting <= 0) || success}
                                    forceReRender={[amountCircRes, amountMeeting, success]}
                                    createOrder={(data, actions) => {
                                        console.log(amountCircRes);
                                        return props.api.createOrder({
                                            "amountCircRes": amountCircRes,
                                            "amountMeeting": amountMeeting
                                        }).then(res => res.json())
                                            .then(response => {
                                                order = response;
                                                return response.paypalOrderId;
                                            })
                                    }}
                                    onApprove={(data, actions) => {
                                        return props.api.checkOrderApproved(order.id).then(res => {
                                            if (res.ok) {
                                                setSuccess(true);
                                            } else {
                                                setErrorMessage("Beim Verarbeiten der PayPal Zahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!");
                                            }
                                        });
                                    }}
                                    onError={(data, actions) => {
                                        setErrorMessage("Beim Verarbeiten der PayPal Zahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!");
                                    }}
                                />
                            </PayPalScriptProvider>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Container>
    );
}