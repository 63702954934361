import React from 'react';
import {Grid} from "@material-ui/core";
import '../App.css';
import {Edit} from "@material-ui/icons";
import {Link} from "react-router-dom";

export default function OwnerRow(props) {

    const owner = props.owner;
    const units = owner.units;

    return (<Grid container className={props.index % 2 === 0 ? "ownerRow, even" : "ownerRow, odd"}>
        <Grid item xs={4}>{owner.firstname} {owner.lastname}</Grid>
        <Grid item xs={4}>{owner.email}</Grid>
        <Grid item xs={3}>{units.map((unit, index) => <Grid container key={index}><Grid item
                                                                            xs={6}>{unit.unit}</Grid>
            <Grid item
                  xs={6}>{unit.stake}</Grid></Grid>)}
        </Grid>
        <Grid item xs={1}>
            <Link to={`/addowner/${owner.objectId}?ownerId=${owner.id}`}><Edit color={props.index % 2 === 0 ? "secondary" : "primary"}/></Link>
        </Grid>
    </Grid>)
}