import React, {useEffect, useState} from "react";
import '../App.css';
import {Box, Button, Grid, Modal, Typography} from "@material-ui/core";
import ResponseRow from "./ResponseRow";

export default function SittingVotingModal(props) {

    const sittingId = props.sittingId;
    const sittingTop = props.sittingTop;
    const attendees = props.attendees;
    const [votingAttendees, setVotingAttendees] = useState([]);
    const [open, setOpen] = useState(false);
    const [openAnswers, setOpenAnswers] = useState(true);
    const [status, setStatus] = useState(sittingTop.status);

    const [noAnswer, setNoAnswer] = useState([0, 0, 0]);
    const [yes, setYes] = useState([0, 0, 0]);
    const [no, setNo] = useState([0, 0, 0]);
    const [abstain, setAbstain] = useState([0, 0, 0]);

    const handleClose = () => {
        clearInterval(timer); // clear
        setOpen(false)
    };

    const handleDecline = () => {
        sittingTop.status = "DECLINED";
        props.api.saveTopSurvey(sittingTop, sittingId);
        handleClose();
        setStatus("DECLINED");
    };

    const handleAccept = () => {
        sittingTop.status = "APPROVED";
        props.api.saveTopSurvey(sittingTop, sittingId);
        handleClose();
        setStatus("APPROVED");
    };

    const [timer, setTimer] = useState(0);

    const fetchData = async () => {

        var tmpNoAnswer = 0;
        var tmpYes = 0;
        var tmpNo = 0;
        var tmpAbstain = 0;

        var tmpNoAnswerMea = 0;
        var tmpYesMea = 0;
        var tmpNoMea = 0;
        var tmpAbstainMea = 0;

        var tmpNoAnswerUnit = 0;
        var tmpYesUnit = 0;
        var tmpNoUnit = 0;
        var tmpAbstainUnit = 0;

        props.api.getTopVotingAttendeeByTopId(sittingId, sittingTop.id)
            .then(response => response.json().then(res => {
                setVotingAttendees(res)

                res.forEach(votingAttendee => {
                    switch (votingAttendee.response) {
                        case -1:
                            tmpNoAnswer++;
                            tmpNoAnswerMea = tmpNoAnswerMea + votingAttendee.mea;
                            tmpNoAnswerUnit = tmpNoAnswerUnit + votingAttendee.unitCount;
                            break;
                        case 0:
                            tmpAbstain++;
                            tmpAbstainMea = tmpAbstainMea + votingAttendee.mea;
                            tmpAbstainUnit = tmpAbstainUnit + votingAttendee.unitCount;
                            break;
                        case 1:
                            tmpYes++;
                            tmpYesMea = tmpYesMea + votingAttendee.mea;
                            tmpYesUnit = tmpYesUnit + votingAttendee.unitCount;
                            break;
                        case 2:
                            tmpNo++;
                            tmpNoMea = tmpNoMea + votingAttendee.mea;
                            tmpNoUnit = tmpNoUnit + votingAttendee.unitCount;
                            break;
                    }
                });


                setNoAnswer([tmpNoAnswer, tmpNoAnswerMea, tmpNoAnswerUnit]);
                setAbstain([tmpAbstain, tmpAbstainMea, tmpAbstainUnit]);
                setYes([tmpYes, tmpYesMea, tmpYesUnit]);
                setNo([tmpNo, tmpNoMea, tmpNoUnit]);

                console.log(res.length);
                console.log(tmpYes + tmpNo + tmpAbstain);

                if (tmpYes + tmpNo + tmpAbstain == res.length) {
                    setOpenAnswers(false);
                } else {
                    setOpenAnswers(true);
                }

            }));
    }


    function handleOpen() {
        props.api.startTopVoting(sittingId, sittingTop.id).then(response => response.json().then(res => setVotingAttendees(res)));
        setOpen(true);


        fetchData();

        console.log("OPEN: " + open);

        if (sittingTop.status === "INPROGRESS") {
            setTimer(setInterval(fetchData, 1000));
        }
    }

    useEffect(() => {
        },
        []
    )

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Grid container>
                {!props.top &&
                    <Grid><Button variant={"outlined"} disabled={status !== "INPROGRESS"} onClick={handleOpen}>Abstimmung
                        starten</Button><br/>Bei Start der Abstimmung erhalten alle Eigentümer / Bevollmächtigten eine Mail mit Link zur Stimmabgabe!</Grid>}
                {status === "APPROVED" && <Typography className={"answerYes"}>Beschluß angenommen</Typography>}
                {status === "DECLINED" && <Typography className={"answerNo"}>Beschluß abgelehnt</Typography>}
            </Grid>
            <Modal
                open={open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {sittingTop.title}
                    </Typography>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={2} className={"answerYes"}>Ja</Grid>
                        <Grid item xs={2} className={"answerNo"}>Nein</Grid>
                        <Grid item xs={2} className={"answerAbstain"}>Enthaltung</Grid>
                        <Grid item xs={2} className={"answerNoResponseYet"}>Keine Antwort</Grid>

                        <Grid item xs={4}>Anzahl Stimmen</Grid>
                        <Grid item xs={2} className={"answerYes"}>{yes[0]}</Grid>
                        <Grid item xs={2} className={"answerNo"}>{no[0]}</Grid>
                        <Grid item xs={2} className={"answerAbstain"}>{abstain[0]}</Grid>
                        <Grid item xs={2} className={"answerNoResponseYet"}>{noAnswer[0]}</Grid>

                        <Grid item xs={4}>MEA</Grid>
                        <Grid item xs={2} className={"answerYes"}>{yes[1]}</Grid>
                        <Grid item xs={2} className={"answerNo"}>{no[1]}</Grid>
                        <Grid item xs={2} className={"answerAbstain"}>{abstain[1]}</Grid>
                        <Grid item xs={2} className={"answerNoResponseYet"}>{noAnswer[1]}</Grid>

                        <Grid item xs={4}>Einheiten</Grid>
                        <Grid item xs={2} className={"answerYes"}>{yes[2]}</Grid>
                        <Grid item xs={2} className={"answerNo"}>{no[2]}</Grid>
                        <Grid item xs={2} className={"answerAbstain"}>{abstain[2]}</Grid>
                        <Grid item xs={2} className={"answerNoResponseYet"}>{noAnswer[2]}</Grid>
                    </Grid>
                    <br/><br/>
                    <Grid container>
                        {attendees.map((attendee, index) =>
                            <Grid container>
                                <Grid item xs={4}>{attendee.firstname} {attendee.lastname} ({attendee.email})</Grid>
                                <Grid item xs={4}>
                                    {attendee.present === false &&
                                        <span>Vertreten von {attendee.trustee.firstname} {attendee.trustee.lastname} ({attendee.trustee.email})</span>}
                                </Grid>
                                <Grid item xs={4}>
                                    {votingAttendees[index] &&
                                        <ResponseRow response={votingAttendees[index].response}/>}
                                </Grid>
                            </Grid>
                        )
                        }
                    </Grid>
                    <br/><br/>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button variant={"contained"} onClick={handleClose}>Abbrechen</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant={"contained"} color={"secondary"} onClick={handleDecline}
                                    disabled={openAnswers}>Abgelehnt</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant={"contained"} color={"primary"} onClick={handleAccept}
                                    disabled={openAnswers}>Angenommen</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div>
    )
}