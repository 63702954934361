import React, {useState} from 'react';
import {Container, TextField} from "@material-ui/core";
import {Delete} from "@material-ui/icons";

export default function OwnerUnits(props) {

    const  unit = props.unit;

    const [unitName, setUnitName] = useState(props.unit.unit);
    const [stake, setStakeForm] = useState(props.unit.stake);

    function setUnit(value) {
        unit.unit = value;
        setUnitName(value);
    }

    function setStake(value) {
        unit.stake = value;
        setStakeForm(value);
    }

    return (<Container>
            Einheit {props.index+1}<br/>
                <TextField label="Einheit" fullWidth={false} value={unit.unit}
                           onInput={e => setUnit(e.target.value)}
                           required={true}/>

                <TextField label="Anteil" fullWidth={false} value={unit.stake}
                           onInput={e => setStake(e.target.value)}
                           required={true} type="number"/>
            { props.index > 0 &&
                <Delete onClick={() => props.deleteUnit()} />
            }
        </Container>
    );
}