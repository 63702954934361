import React from "react";
import '../App.css';
import {Button, Container, Grid, Paper, Typography} from "@material-ui/core";
import SittingVotingModal from "./SittingVotingModal";
import {useHistory} from "react-router-dom";

export default function SittingTop(props) {
    const sittingId = props.sittingId;
    const objectId = props.objectId;
    const top = props.top;
    const attendees = props.attendees;

    const history = useHistory();

    function handleCompletion() {
        props.api.setSittingStatus(sittingId, "finished");
        history.push(`/object/${objectId}?tab=2&sittingEnded=true`);
    }

    return (
        <Container>
            <Paper>
                <Typography variant="h5" color="secondary">TOP {props.currentStep - 1}: {top.title}</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {top.description}
                    </Grid>
                    {top.type &&
                        <Grid item xs={12}><SittingVotingModal attendees={attendees} sittingTop={top} api={props.api}
                                                               sittingId={sittingId}/></Grid>}
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Button variant="contained" color="secondary" onClick={props.previousStep}>Zurück</Button>
                    </Grid>
                    <Grid item xs={2}>
                        {props.currentStep < props.totalSteps &&
                            <Button variant="contained" color="primary" onClick={props.nextStep}>Weiter</Button>}
                        {props.currentStep === props.totalSteps &&
                            <Button variant="contained" color="primary" onClick={handleCompletion}>Versammlung
                                beenden</Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}