import React, {useState} from "react";
import '../App.css';
import {Checkbox, Grid, MenuItem, Select} from "@material-ui/core";
import TrusteeModal from "./TrusteeModal";

export default function SittingOwner(props) {

    const owner = props.owner;
    const attendees = props.attendees;
    const trustees = props.trustees;
    const [open, setOpen] = useState(false);
    const [selectedTrustee, setSelectedTrustee] = useState('');

    const [present, setPresent] = useState(false);

    function addTrustee(trustee) {
        props.setTrustees([...trustees, trustee]);
        attendees[props.index].present = false;
        attendees[props.index].trustee = trustee;
        props.setAttendees([...attendees]);
    }

    function setTrusteePresent(value) {
        setSelectedTrustee(value);
        attendees[props.index].present = false;
        attendees[props.index].trustee = value;
        props.setAttendees([...attendees]);
    }

    function setOwnerPresent(e) {
        setPresent(!present);
        if (!present) {
            attendees[props.index].present = true;
            attendees[props.index].trustee = null;
            props.setAttendees([...attendees]);
        }
    }

    return (
        <>
            <Grid item xs={5}>{owner.firstname} {owner.lastname}</Grid>
            <Grid item xs={3}><Checkbox onInput={setOwnerPresent} required={selectedTrustee === ''}/>Anwesend</Grid>
            <Grid item xs={1}>
                Vollmacht:
            </Grid>
            <Grid item xs={3}>
                <Select label="Bevollmächtigter" id="trustee" disabled={present} value={selectedTrustee}
                        onChange={e => setTrusteePresent(e.target.value)}>
                    <MenuItem value={''}>-</MenuItem>
                    {trustees.map((trustee, index) => <MenuItem disabled={trustee.id === owner.id}
                        value={trustee}>{trustee.firstname} {trustee.lastname}</MenuItem>)}
                    <MenuItem onClick={() => setOpen(true)}>neuer Bevollmächtigter</MenuItem>
                </Select>
            </Grid>
            <TrusteeModal open={open} setOpen={setOpen} addTrustee={addTrustee} setSelectedTrustee={setSelectedTrustee}/>
        </>
    );
}