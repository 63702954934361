import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, InputLabel, Modal, TextareaAutosize, TextField, Typography} from "@material-ui/core";
import {AddCircleOutline, Delete, Edit} from "@material-ui/icons";

export default function TopModal(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function handleTopSubmit(event) {
        event.preventDefault();
        if (!props.top) {
            props.addTop({title: title, description: description, pos: props.index});
            setTitle('');
            setDescription('');
        } else {
            props.updateTop({title: title, description: description, pos: props.index}, props.index);
        }
        handleClose();
    }

    useEffect(() => {
        if (!props.top) {
            setTitle('');
            setDescription('');
        } else {
            setTitle(props.top.title);
            setDescription(props.top.description);
        }
    }, []);

    return (
        <div>
            {!props.top && <Button variant={"outlined"} onClick={handleOpen} className={props.class}><AddCircleOutline/>TOP</Button>}
            {props.top && <Edit onClick={handleOpen} className={props.class}/>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        TOP {props.index+1} <Delete onClick={props.deleteTop}/>
                    </Typography>
                    <form onSubmit={handleTopSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField label="Titel" fullWidth={false} value={title}
                                           onInput={e => setTitle(e.target.value)}
                                           required={true}/>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel>Beschreibung*</InputLabel>
                                <TextareaAutosize minRows={5} value={description} maxLength={6000}
                                                  onInput={e => setDescription(e.target.value)}
                                                  required={true} style={{width: "100%"}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary" onClick={handleClose}>Abbrechen</Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <Button variant="contained" color="primary" type="submit">Speichern</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    )
        ;
}