import React, {useEffect, useState} from "react";
import '../App.css';
import {Button, Container, Grid, Paper, Typography} from "@material-ui/core";
import SittingOwner from "./SittingOwner";

export default function PresenceCheck(props) {

    const owners = props.owners;
    const attendees = props.attendees;
    const sittingId = props.sittingId;
    const [trustees, setTrustees] = useState([]);

    function handleSubmit(event) {
        if (event.target.id === "presenceCheckForm") {
            event.preventDefault();
            saveAttendees();
            props.nextStep();
        }
    }

    function saveAttendees() {
        var attendeesToSave = [];
        attendees.forEach(attendee => {
            if (attendee.present === true) {
                attendeesToSave.push({
                    id: attendee.attendeeId,
                    sittingId: sittingId,
                    firstname: attendee.firstname,
                    lastname: attendee.lastname,
                    email: attendee.email,
                    ownerId: attendee.id,
                    present: true
                });
            } else {
                attendeesToSave.push({
                    id: attendee.attendeeId,
                    sittingId: sittingId,
                    firstname: attendee.trustee.firstname,
                    lastname: attendee.trustee.lastname,
                    email: attendee.trustee.email,
                    ownerId: attendee.id,
                    present: false
                });
            }
        });

        props.api.createSittingAttendees(attendeesToSave, sittingId).then(response => response.json().then(res => {
            for (var i = 0; i < res.length; i++) {
                attendees[i].attendeeId = res[i].id;
            }
        }));

        props.setAttendees([...attendees]);
    }

    useEffect(() => {
        setTrustees([...owners]);
    }, [owners]);

    return (
        <Container>
            <Paper>
                <form onSubmit={handleSubmit} id={"presenceCheckForm"}>
                    <Typography variant="h5" color="secondary">Anwesenheit überprüfen</Typography>
                    <Grid container spacing={2}>
                        {owners.map((owner, index) => <SittingOwner key={index} index={index} owner={owner}
                                                                    owners={props.owners} trustees={trustees}
                                                                    setTrustees={setTrustees} attendees={attendees}
                                                                    setAttendees={props.setAttendees}/>)}
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" type={"submit"}>Weiter</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}