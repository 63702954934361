import React, {useState} from 'react';
import {Box, Button, Grid, Modal, TextField, Typography} from "@material-ui/core";


export default function TrusteeModal(props) {

    const open = props.open;
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => {
        //props.setTrustee('');
        props.setOpen(false)
    };

    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function handleTrusteeSubmit(event) {
        console.log(event);
        event.preventDefault();
        var trustee = {firstname:firstname, lastname:lastname, email:email};
        props.addTrustee(trustee);
        props.setSelectedTrustee(trustee);
        handleClose();
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        neuer Bevollmächtigter
                    </Typography>
                    <form onSubmit={handleTrusteeSubmit} id={"trusteeSubmit"}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="Vorname" fullWidth={false} value={firstname}
                                           onInput={e => setFirstname(e.target.value)}
                                           required={true}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Nachname" fullWidth={false} value={lastname}
                                           onInput={e => setLastname(e.target.value)}
                                           required={true}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="eMail" fullWidth={false} value={email}
                                           onInput={e => setEmail(e.target.value)}
                                           required={true} type="email"/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary" onClick={handleClose}>Abbrechen</Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <Button variant="contained" color="primary" type="submit">Speichern</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    )
        ;
}