import React, {useEffect, useState} from 'react';
import {Button, Container, Grid, IconButton, Paper, TextField, Typography} from "@material-ui/core";
import ButtonAppBar from "../AppBar";
import {Link, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {DropzoneArea} from "material-ui-dropzone";
import ConfirmDialog from "../util/ConfirmDialog";
import {DeleteForever} from "@material-ui/icons";

export default function AddObject(props) {

    const history = useHistory();
    const [files, setFiles] = useState([])
    const [id, setId] = useState();
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);

    const useStyles = makeStyles({
        link: {
            textDecoration: "none"
        }
    });

    function handleSubmit(event) {
        event.preventDefault();

        if (files[0]) {
            props.api.uploadObjectFile(files[0]).then(response => {
                    response.text().then(fileId =>
                        props.api.createObject({
                            "id": id,
                            "name": name,
                            "street": street,
                            "postalcode": postalCode,
                            "city": city,
                            "fileId": fileId
                        }).then(() => history.push("/")))
                }
            )
        } else {
            props.api.createObject({
                "id": id,
                "name": name,
                "street": street,
                "postalcode": postalCode,
                "city": city,
                "fileId": 1
            }).then(() => history.push("/"))
        }
    }

    function deleteObject() {
        if (id) {
            props.api.deleteObject(id).then(() => history.push(`/`));
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        if (query.get("objectId")) {
            props.api.getObjectById(query.get("objectId")).then(res => res.json())
                .then(response => {
                    setId(response.id);
                    setName(response.name);
                    setStreet(response.street);
                    setPostalCode(response.postalcode);
                    setCity(response.city);
                    props.api.getObjectFile(query.get("objectId")).then(res => res.json())
                        .then(response => {
                            const url = 'data:' + response.type + ';base64,' + response.data;
                            fetch(url)
                                .then(res => res.blob())
                                .then(blob => {
                                    const file = new File([blob], response.name, {type: response.type});
                                    setFiles([file]);
                                })
                        })

                    //setFiles([{file: {parts: response.data, type: response.type, name: response.name}}]));
                });
        }
    }, []);

    return (<Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container>
                <Paper>
                    <Typography variant="h5" color="secondary">Neues Objekt anlegen {id &&
                        <IconButton aria-label="delete"
                                    onClick={() => setConfirmOpen(true)}>
                            <DeleteForever/>
                        </IconButton>}
                        <ConfirmDialog
                            title="Objekt löschen?"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onConfirm={deleteObject}
                        >
                            Objekt wirklich löschen?
                        </ConfirmDialog>
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label="Name" fullWidth={true} value={name}
                                                   onInput={e => setName(e.target.value)}
                                                   required={true}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Straße" fullWidth={true} required={true} value={street}
                                                   onInput={e => setStreet(e.target.value)}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Postleitzahl" fullWidth={true} required={true}
                                                   value={postalCode}
                                                   onInput={e => setPostalCode(e.target.value)}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Ort" fullWidth={true} required={true} value={city}
                                                   onInput={e => setCity(e.target.value)}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link to="/" style={{textDecoration: 'none', color: '#000000'}}><Button
                                            variant="contained"
                                            color="secondary">Abbrechen</Button></Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" color="primary" type="submit">Speichern</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    initialFiles={files}
                                    dropzoneText={"Klicken oder Bild hier her ziehen"}
                                    onChange={(files) => setFiles(files)}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

            </Container>
        </Container>
    )
        ;


}