import {Box, Button, Grid, Link, Modal, Typography} from "@material-ui/core";
import React, {useState} from 'react';
import {InfoRounded, LinkRounded} from "@material-ui/icons";
import '../App.css';

export default function CircResModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const circres = props.circres;
    const owners = props.owners;
    const circresOwners = props.circresOwners;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function getOwnerWithId(id) {
        var returnOwner;
        owners.forEach(owner => {
            if (owner.id == id) {
                returnOwner = owner;
            }
        })
        if (returnOwner) {
            return returnOwner.firstname + " " + returnOwner.lastname;
        }
        else {
            return "";
        }
    }

    return (
        <div>
            <Button onClick={handleOpen}><InfoRounded className={props.class}/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {new Intl.DateTimeFormat("de-DE", {
                            year: "numeric",
                            month: "numeric",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric"
                        }).format(Date.parse(circres.date))} - {circres.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {circres.description}
                    </Typography>
                    <br/>
                    <br/>
                    <Grid container>
                        {circresOwners.map((circresOwner, index) =>
                            <Grid container>
                                <Grid item xs={4}>{getOwnerWithId(circresOwner.ownerId)}</Grid>
                                <Grid item xs={4}>
                                    {circresOwner.response == -1 &&
                                        <Typography className={"answerNoResponseYet"}>keine Antwort</Typography>}
                                    {circresOwner.response == 0 &&
                                        <Typography className={"answerAbstain"}>enthalten</Typography>}
                                    {circresOwner.response == 1 &&
                                        <Typography className={"answerYes"}>zugestimmt</Typography>}
                                    {circresOwner.response == 2 &&
                                        <Typography className={"answerNo"}>abgelehnt</Typography>}
                                </Grid>
                                <Grid item xs={4}>
                                    <Link href={circresOwner.link} target={"_blank"}><LinkRounded/></Link>
                                </Grid>
                            </Grid>
                        )
                        }
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}