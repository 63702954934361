import React, {useEffect, useState} from "react";
import '../App.css';
import ButtonAppBar from "../AppBar";
import {Button, Container, Grid, InputLabel, Paper, TextField, Typography} from "@material-ui/core";
import TopModal from "./TopModal";
import {Link, useHistory, useParams} from "react-router-dom";
import TopRow from "./TopRow";
import TopVotingModal from "./TopVotingModal";


export default function AddSitting(props) {

    const history = useHistory();
    const [sittingId, setSittingId] = useState();
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const objectId = useParams();
    const [message, setMessage] = useState();

    const [topList, setTopList] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();
        var tops = [];
        var topssurvey = [];

        topList.forEach(top => {
                if (top.type) {
                    topssurvey.push(top);
                } else {
                    tops.push(top);
                }
            }
        )

        const query = new URLSearchParams(props.location.search);

        var sitting = {
            id: sittingId,
            date: date,
            time: time,
            objectId: objectId.objectId,
            tops: tops,
            topssurvey: topssurvey
        };

        props.api.createSitting(sitting).then(response => {
            if (response.ok) {

                var files2save = [];

                sitting.topssurvey.forEach(topsurvey => {
                    if (topsurvey.files) {
                        topsurvey.files.pos = topsurvey.pos
                        files2save.push(topsurvey.files);
                    }
                });

                response.json().then(createdSitting => {

                    if (files2save.length > 0) {
                        createdSitting.topssurvey.forEach(topsurvey => {
                            files2save.forEach(files => {
                                if (files.pos === topsurvey.pos) {
                                    files.forEach(file => {
                                        props.api.uploadTopSurveyFile(file, topsurvey.id);
                                    });
                                }
                            })
                        });
                    }
                    setMessage('');
                    history.push("/object/" + objectId.objectId + "?tab=2");
                })
            } else {
                setMessage("Fehler beim Speichern der Versammlung (" + response.status + ")");
            }
        });
    }

    function addTop(top) {
        setTopList([...topList, top]);
    }

    function updateTop(top, index) {
        var newTopList = [...topList];
        newTopList[index] = top;
        setTopList(newTopList);
    }

    function deleteTop(index) {
        console.log("Delete top at index " + index);
        topList.splice(index, 1);
        var i = 0;
        topList.forEach(top => {
            top.pos = i;
            i++;
        })
        setTopList([...topList]);
    };

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    useEffect(() => {
            const query = new URLSearchParams(props.location.search);
            if (query.get("sittingId")) {
                props.api.getSittingById(query.get("sittingId")).then(res => res.json())
                    .then(response => {
                            setSittingId(response.id);
                            setDate(response.date.substring(0, 10));
                            setTime(response.time);

                            var topList = response.tops;
                            if (response.topssurvey) {
                                response.topssurvey.forEach(top => {
                                    topList.splice(top.pos, 0, top);
                                });
                            }
                        setTopList([...topList]);
                        }
                    )
                ;
            }
        }
        ,
        []
    )
    ;

    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container>
                <Paper>
                    <Typography variant="h5" color="secondary">Versammlung planen</Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>

                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6" color="secondary">Tagesordnungspunkte</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Datum</InputLabel>
                            <TextField type={"date"} value={date} onInput={e => setDate(e.target.value)}
                                       required={true}></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TopModal addTop={addTop} index={topList.length}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TopVotingModal addTop={addTop} api={props.api} index={topList.length}/>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Uhrzeit</InputLabel>
                            <TextField type={"time"} value={time} onInput={e => setTime(e.target.value)}
                                       required={true}></TextField>
                        </Grid>
                        <Grid item xs={8} style={{overflow: "scroll", maxWidth: "800px", height: "300px"}}>
                            {topList.map((top, index) => <TopRow key={index} index={index} top={top}
                                                                 deleteTop={() => deleteTop(index)}
                                                                 updateTop={updateTop} api={props.api}/>)}
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Link to={"/object/" + objectId.objectId + "?tab=2"}
                                  style={{textDecoration: 'none', color: '#000000'}}><Button
                                variant="contained"
                                color="secondary">Abbrechen</Button></Link>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: "right"}}>
                            <form onSubmit={handleSubmit}>
                                <Button variant="contained" color="primary" type="submit">Speichern</Button>
                                <Typography color="error">{message}</Typography>
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Container>
    );
}