import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {ExitToApp, Help, HomeOutlined, Money} from "@material-ui/icons";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: 100,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    userButton: {
        //border: "1px solid #FFFFFF"
    }
}));

export default function ButtonAppBar(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <Link to={"/"}><HomeOutlined/></Link>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        WEG<br/>
                        Online
                    </Typography>
                    {props.keycloak && <div>
                        <Link to="/buy"><Button color="inherit" variant={"text"}
                                                className={classes.userButton}>Kaufen<Money/></Button></Link>
                        <Button color="inherit" variant={"text"}
                                className={classes.userButton}>Hilfe <Help/></Button>&nbsp;&nbsp;
                        <Button color="inherit" className={classes.userButton}
                                onClick={() => props.keycloak.logout()}>Ausloggen <ExitToApp/></Button>
                    </div>
                    }
                </Toolbar>
            </AppBar>
        </div>

    );
}
