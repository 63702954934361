import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextareaAutosize,
    TextField,
    Typography
} from "@material-ui/core";
import ButtonAppBar from "../AppBar";
import {Link, useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {DeleteForever} from "@material-ui/icons";
import ConfirmDialog from "../util/ConfirmDialog";
import {DropzoneArea} from "material-ui-dropzone";

export default function AddCircres(props) {

    const history = useHistory();
    const objectId = useParams();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [note, setNote] = useState('');
    const [files, setFiles] = useState([]);
    const [type, setType] = useState(1);

    const useStyles = makeStyles({
        link: {
            textDecoration: "none"
        }
    });

    function handleSubmit(event) {
        event.preventDefault();
        props.api.createCircRes({
            objectId: objectId.objectId,
            title: title,
            description: description,
            note: note,
            type: type
        }).then(response => {
            setMessage('');
            if (response.ok) {
                if (files.length > 0) {
                    response.json().then(res => {
                        props.api.uploadCircresFile(files, res.id);
                    })
                }
                history.push("/object/" + objectId.objectId + "?tab=3");
            } else {
                setMessage("Fehler beim Speichern des Umlaufbeschlusses (" + response.status + ")");
            }
        });
    }

    function deleteOwner()
    {
        props.api.archiveCircRes().then(() => history.push(`/object/${objectId.objectId}`));
    }

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        if (query.get("circResId")) {
            //TODO
        }


    }, []);


    return (<Container>
        <ButtonAppBar keycloak={props.keycloak}/>
        <Container>
            <Paper>
                <Typography variant="h5" color="secondary">Neuen Umflaufbeschluss anlegen</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Typ</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={type}
                                            label="Typ"
                                            onChange={e => setType(e.target.value)}
                                        >
                                            <MenuItem value={1}>pro Kopf</MenuItem>
                                            <MenuItem value={2}>Einheiten</MenuItem>
                                            <MenuItem value={3}>MEA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Titel" fullWidth={true} value={title}
                                               onInput={e => setTitle(e.target.value)}
                                               required={true}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Beschreibung</InputLabel>
                                    <TextareaAutosize rows={5} value={description}
                                               onInput={e => setDescription(e.target.value)}
                                               required={true} style={{ width: "100%" }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Bemerkung (intern)</InputLabel>
                                    <TextareaAutosize rows={3} value={note}
                                               onInput={e => setNote(e.target.value)} style={{ width: "100%" }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    Dokumente:
                                </Grid>
                                <Grid item>
                                    <DropzoneArea
                                        dropzoneText={"Klicken oder Bild hier her ziehen"}
                                        onChange={(files) => setFiles(files)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>

                        </Grid>
                        <Grid item xs={6}>
                            Bei Klick auf Speichern erhalten alle Eigentümer eine Mail mit Link zur Stimmabgabe!
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Link to={"/object/" + objectId.objectId + "?tab=3"}
                                  style={{textDecoration: 'none', color: '#000000'}}><Button
                                variant="contained"
                                color="secondary">Abbrechen</Button></Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" type="submit">Speichern</Button>
                            <Typography color="error">{message}</Typography>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    </Container>);
}