import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import '../App.css';
import CircResModal from "./CircResModal";
import {PictureAsPdf} from "@material-ui/icons";

export default function CircresRow(props) {

    const circres = props.circres;
    const owners = props.owners;

    const [noAnswer, setNoAnswer] = useState(0);
    const [yes, setYes] = useState(0);
    const [no, setNo] = useState(0);
    const [abstain, setAbstain] = useState(0);
    const [circresOwners, setCircresOwners] = useState([]);

    async function getPDF() {
        await props.api.getCircResPDF(circres.id).then(response => {
            const filename = response.headers.get('Content-Disposition').split('filename=')[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            });
        })
    }

    useEffect(() => {

        const fetchData = async () => {

            var tmpNoAnswer = 0;
            var tmpYes = 0;
            var tmpNo = 0;
            var tmpAbstain = 0;

            props.api.getCircResOwnerByCircresId(circres.id).then(res => res.json())
                .then(response => {
                    if (response._embedded.circresowner) {
                        setCircresOwners(response._embedded.circresowner);
                        response._embedded.circresowner.forEach(circresowner => {
                            switch (circresowner.response) {
                                case -1:
                                    tmpNoAnswer++;
                                    break;
                                case 0:
                                    tmpAbstain++;
                                    break;
                                case 1:
                                    tmpYes++;
                                    break;
                                case 2:
                                    tmpNo++;
                                    break;
                            }
                        });

                        setNoAnswer(tmpNoAnswer);
                        setAbstain(tmpAbstain);
                        setYes(tmpYes);
                        setNo(tmpNo);
                    }
                });
        }

        fetchData();

        if (circres.status === "INPROGRESS") {
            const t = setInterval(fetchData, 5000);

            return () => clearInterval(t); // clear
        }

    }, []);


    return (<Grid container className={props.index % 2 == 0 ? "even" : "odd"}>
        <Grid item xs={3}>{new Intl.DateTimeFormat("de-DE", {
            year: "numeric",
            month: "numeric",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric"
        }).format(Date.parse(circres.date))}</Grid>
        <Grid item xs={3}>{circres.title}</Grid>
        <Grid item xs={2}>
            {circres.status == "INPROGRESS" && "in Bearbeitung"}
            {circres.status == "APPROVED" && "angenommen"}
            {circres.status == "DECLINED" && "abgelehnt"}
        </Grid>
        <Grid item xs={2}>
            <span className={"answerYes"}>{yes}</span>
            <span className={"answerAbstain"}>{abstain}</span>
            <span className={"answerNoResponseYet"}>{noAnswer}</span>
            <span className={"answerNo"}>{no}</span>
        </Grid>
        <Grid item xs={1}>
            <CircResModal circres={circres} owners={owners} circresOwners={circresOwners}
                          class={props.index % 2 == 0 ? "even" : "odd"}/>
        </Grid>
        <Grid item xs={1}>
            {circres.status != "INPROGRESS" && <PictureAsPdf className={"iconButton"} onClick={() => getPDF()}/>}
        </Grid>
    </Grid>)
}