class Api {

    constructor(keycloak) {
        if (keycloak) {
            this.keycloak = keycloak;
            this.authToken = keycloak.token;

            keycloak.loadUserInfo().then(userInfo => {
                console.log(userInfo);
            })

            //keycloak.onTokenExpired = () => console.log("Token expired!!!");
        }
    }

    headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    pdfHeaders = {
        'Accept': 'application/pdf',
        'Content-Type': 'application/json'
    };
    
    BASE_URL_SITTING = '/api/sitting';
    BASE_URL_OWNER = '/api/owner';
    BASE_URL_VOTING = '/api/voting';
    BASE_URL_OBJECT = '/api/object';
    BASE_URL_CIRCRES = '/api/circres';
    BASE_URL_CIRCRESCTRL = '/api/circresctrl';
    BASE_URL_CIRCRES_OWNER = '/api/circresowner';
    BASE_URL_CIRCRES_FILE = '/api/circresfile';
    BASE_URL_OBJECT_FILE = '/api/objectfile';
    BASE_URL_TOPSURVEY = '/api/topsurvey';
    BASE_URL_TOPSURVEY_FILE = '/api/topsurveyfile';
    BASE_URL_UNIT = '/api/unit';
    BASE_URL_ORDERS = '/api/orders';
    BASE_URL_PRIZES = '/api/prizes';

    checkToken() {
        if (this.keycloak) {
            this.keycloak.updateToken(5).then(refreshed => {
                if (refreshed) {
                    this.authToken = this.keycloak.token;
                }
            });
        }
    }

    createHeaders() {

        this.checkToken();

        return this.authToken ? {
            ...this.headers,
            'Authorization': 'Bearer ' + this.authToken
        } : this.headers;
    }

    createPDfHeaders() {
        return this.authToken ? {
            ...this.pdfHeaders,
            'Authorization': 'Bearer ' + this.authToken
        } : this.pdfHeaders;
    }

    createAuthHeader() {
        return {'Authorization': 'Bearer ' + this.authToken};
    }

    async getAllSittings() {
        return await fetch(this.BASE_URL_SITTING, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getSittingById(id) {
        return await fetch(`${this.BASE_URL_SITTING}/${id}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getSittingsByObjectId(objectId) {
        return await fetch(`${this.BASE_URL_SITTING}/search/findByObjectIdOrderByDateDesc?objectId=${objectId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getNextSitting(objectId, status) {
        return await fetch(`${this.BASE_URL_SITTING}/search/getFirstByObjectIdAndStatusOrderByDateAsc?objectId=${objectId}&status=${status}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getSittingsByStatus(status) {
        return await fetch(`${this.BASE_URL_SITTING}/search/findByStatus?status=${status}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async deleteSitting(id) {
        return await fetch(`${this.BASE_URL_SITTING}/${id}`, {
            method: 'DELETE',
            headers: this.createHeaders()
        });
    }

    async updateSitting(item) {
        return await fetch(`${this.BASE_URL_SITTING}/${item.id}`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async createSitting(item) {
        return await fetch(this.BASE_URL_SITTING, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async createSittingAttendees(attendees, sittingId) {
        return await fetch(`${this.BASE_URL_SITTING}/${sittingId}/sittingattendees`, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(attendees),
        });
    }

    async getSittingAttendees(sittingId) {
        return await fetch(`${this.BASE_URL_SITTING}/${sittingId}/sittingattendees`, {
            method: 'GET',
            headers: this.createHeaders(),
        })
    }

    async createTopSurvey(item) {
        return await fetch(this.BASE_URL_TOPSURVEY, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async getTopSurveyFiles(topsurveId) {
        return await fetch(`${this.BASE_URL_TOPSURVEY_FILE}/search/findByTopsurveyId?topsurveyId=${topsurveId}`, {
            method: 'GET',
            headers: this.createHeaders(),
        })
    }

    async deleteUnit(id) {
        return await fetch(`${this.BASE_URL_UNIT}/${id}`, {
            method: 'DELETE',
            headers: this.createHeaders()
        });
    }

    async createCircRes(item) {

        //   console.log(JSON.stringify(item));

        return await fetch(this.BASE_URL_CIRCRESCTRL, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async getCircResById(id) {
        return await fetch(`${this.BASE_URL_CIRCRES}/${id}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getCircResByObjectId(objectId) {
        return await fetch(`${this.BASE_URL_CIRCRES}/search/findByObjectIdOrderByDateDesc?objectId=${objectId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getOpenCircResCount(objectId) {
        return await fetch(`${this.BASE_URL_CIRCRES}/search/countByObjectIdAndStatus?objectId=${objectId}&status=INPROGRESS`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getCircResByObjectIdAndArchived(objectId, archived) {
        return await fetch(`${this.BASE_URL_CIRCRES}/search/findByObjectIdAndArchivedOrderByDateDesc?objectId=${objectId}&archived=${archived}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async updateCircRes(item) {
        return await fetch(`${this.BASE_URL_CIRCRES}/${item.id}`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item)
        });
    }

    async getCircResOwner(linkid) {
        return await fetch(`${this.BASE_URL_CIRCRES_OWNER}/search/findByLink?link=${linkid}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getTopVotingAttendee(linkid) {
        return await fetch(`/api/topvotingattendee?link=${linkid}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getCircResOwnerByCircresId(circresId) {
        return await fetch(`${this.BASE_URL_CIRCRES_OWNER}/search/findByCircresId?circresid=${circresId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async updateCircResOwner(item) {
        return await fetch(`/api/savesurvey`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item)
        });
    }

    async getTopVotingAttendeeByTopId(sittingId, topId) {
        return await fetch(`/api/sitting/${sittingId}/topvotingattendees/${topId}`, {
            method: 'GET',
            headers: this.createAuthHeader()
        })
    }

    async saveTopVote(item) {
        return await fetch(`/api/savetopvoting`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item)
        });
    }

    async saveTopSurvey(top, sittingId) {
        return await fetch(`/api/sitting/${sittingId}/topsurvey`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(top)
        })
    }

    async createObject(item) {
        return await fetch(this.BASE_URL_OBJECT, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async updateObject(item) {
        return await fetch(`${this.BASE_URL_OBJECT}/${item.id}`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async deleteObject(id) {
        return await fetch(`${this.BASE_URL_OBJECT}/${id}`, {
            method: 'DELETE',
            headers: this.createHeaders()
        });
    }

    async getAllObject() {
        return await fetch(this.BASE_URL_OBJECT, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getObjectById(id) {
        return await fetch(`${this.BASE_URL_OBJECT}/${id}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async createOwner(owner) {
        return await fetch(this.BASE_URL_OWNER, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(owner),
        });
    }

    async getOwnerByObjectId(objectId) {
        return await fetch(`${this.BASE_URL_OWNER}/search/findByObjectId?objectId=${objectId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getOwnerById(ownerId) {
        return await fetch(`${this.BASE_URL_OWNER}/${ownerId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getOwnerCountByObject(objectId) {
        return await fetch(`${this.BASE_URL_OWNER}/search/countByObjectId?objectId=${objectId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async deleteOwner(id) {
        return await fetch(`${this.BASE_URL_OWNER}/${id}`, {
            method: 'DELETE',
            headers: this.createHeaders()
        });
    }

    async updateOwner(item) {
        return await fetch(`${this.BASE_URL_OWNER}/${item.id}`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async deleteVoting(id) {
        return await fetch(`${this.BASE_URL_VOTING}/${id}`, {
            method: 'DELETE',
            headers: this.createHeaders()
        });
    }

    async updateVoting(item) {
        return await fetch(`${this.BASE_URL_VOTING}/${item.id}`, {
            method: 'PUT',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async createVoting(item) {
        return await fetch(this.BASE_URL_VOTING, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(item),
        });
    }

    async getVotingBySittingId(sittingId) {
        return await fetch(`${this.BASE_URL_VOTING}/search/findBySittingId?sittingId=${sittingId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getVotingById(votingId) {
        return await fetch(`${this.BASE_URL_VOTING}/${votingId}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getCircResFile(circresId) {
        return await fetch(`${this.BASE_URL_CIRCRES}/${circresId}/file`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async uploadCircresFile(files, circresId) {

        files.forEach(file => {
            var data = new FormData()
            data.append("circresId", circresId);
            data.append('file', file);
            data.append('filename', file.name);

            fetch(this.BASE_URL_CIRCRES_FILE, {
                method: 'POST',
                headers: this.createAuthHeader(),
                body: data
            });
        })
    }

    async uploadObjectFile(file) {

        if (!file)
        {
            return "";
        }

        var data = new FormData()
        data.append('file', file)

        return await fetch(this.BASE_URL_OBJECT_FILE, {
            method: 'POST',
            headers: this.createAuthHeader(),
            body: data
        });
    }

    async uploadTopSurveyFile(file, topsurveyId) {

        var data = new FormData()
        data.append('file', file);
        data.append('topsurveyId', topsurveyId);

        return await fetch(this.BASE_URL_TOPSURVEY_FILE, {
            method: 'POST',
            headers: this.createAuthHeader(),
            body: data
        });
    }

    async getObjectFile(id) {
        return await fetch(`${this.BASE_URL_OBJECT_FILE}/${id}`, {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getCircResPDF(circresId) {
        return await fetch(`/api/circresSummary/${circresId}`, {
            method: 'GET',
            headers: this.createPDfHeaders()
        })
    }

    async getSittingPDF(sittingId) {
        return await fetch(`/api/sitting/${sittingId}/pdf`, {
            method: 'GET',
            headers: this.createPDfHeaders()
        })
    }

    async startTopVoting(sittingId, topId) {
        return await fetch(`/api/sitting/${sittingId}/topvoting/${topId}`, {
            method: 'POST',
            headers: this.createHeaders()
        })
    }

    async setSittingStatus(sittingId, status) {
        return await fetch(`/api/sitting/${sittingId}/status`, {
            method: 'PUT',
            headers: this.createAuthHeader(),
            body: status
        })
    }

    async createOrder(createOrder) {
        return await fetch(this.BASE_URL_ORDERS, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(createOrder),
        });
    }

    async checkOrderApproved(orderId) {
        return await fetch(this.BASE_URL_ORDERS + '/checkStatus/' + orderId,  {
            method: 'GET',
            headers: this.createHeaders()
        });
    }

    async getPrizes() {
        return await fetch(this.BASE_URL_PRIZES, {
            method: 'GET',
            headers: this.createHeaders()
        })
    }
}

export default Api;