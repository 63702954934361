import ButtonAppBar from "./AppBar";
import React, {useEffect, useState} from "react";
import './App.css';
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";


export default function Survey(props) {

    const [message, setMessage] = useState('');
    const [owner, setOwner] = useState('');
    const [survey, setSurvey] = useState('');
    const [response, setResponse] = useState();
    const [files, setFiles] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();
        var item = {
            link: owner.link,
            response: response
        };

        props.api.updateCircResOwner(item).then(response => {
            if (!response.ok) {
                setMessage("Es ist ein Fehler aufgetreten ("+ response.status + ")");
            } else {
                setMessage("Antwort gespeichert");
            }
        });


    }

    useEffect(() => {
        setMessage();
        props.api.getCircResOwner(window.location.href).then(responseCircRes => {
            if (!responseCircRes.ok) {
                setMessage("Es ist ein Fehler aufgetreten (" + responseCircRes.status + ")");
            } else {
                responseCircRes.json().then(res => {
                    setOwner(res);
                    setResponse(res.response);
                    if (res.response > -1) {
                        setMessage("Sie haben bereits geantwortet ");
                    }
                    props.api.getCircResById(res.circresId).then(response2 => {
                        if (!response2.ok) {
                            setMessage("Es ist ein Fehler aufgetreten (" + response2.status + ")");
                        } else {
                            response2.json().then(res2 => {
                                setSurvey(res2);
                                props.api.getCircResFile(res2.id)
                                    .then(response3 => response3.json())
                                    .then(res3 => setFiles(res3._embedded.circResFileses));
                            })

                        }
                    });
                });
            }
        })
    }, []);

    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container style={{border:"2px solid orange"}}>{message}</Container>
            <br/><br/>
            <Paper>
                <Typography variant="h5" color="secondary">{survey.title}</Typography>
                {survey.description}
                <br/><br/>
                <Grid container>
                    <Grid item xs={6}>
                        <form onSubmit={handleSubmit}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Antwort:</FormLabel>
                                <RadioGroup defaultValue="female" aria-label="Antwort" name="customized-radios"
                                            value={response}
                                            onChange={e => setResponse(e.target.value)}>
                                    <FormControlLabel value="1" control={<Radio/>} label="Ja"
                                                      disabled={message}/>
                                    <FormControlLabel value="2" control={<Radio/>} label="Nein"
                                                      disabled={message}/>
                                    <FormControlLabel value="0" control={<Radio/>} label="Enthaltung"
                                                      disabled={message}/>
                                </RadioGroup>
                                <Button variant="contained" color="primary" type="submit"
                                        disabled={message}>Speichern</Button>
                            </FormControl>
                        </form>
                    </Grid>
                    <Grid item xs={6}>
                        {files.length > 0 ? "Anhänge" : "keine Anhänge vorhanden"}
                        <ul>
                            {files.map((file, index) =>
                                <li><a href={`data:${file.type};base64,${file.data}`}
                                       download={file.name}>{file.name}</a></li>
                            )}
                        </ul>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}