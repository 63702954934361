import React, {useEffect, useState} from 'react';
import ButtonAppBar from "./AppBar";
import ObjectOverviewCard from "./object/ObjectOverviewCard";
import {Card, CardActionArea, CardContent, Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AddCircleOutline} from "@material-ui/icons";
import {Link} from "react-router-dom";
import './App.css';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));


export default function Overview(props) {

    const [objects, setObjects] = useState([]);

    useEffect(() => {
        props.api.getAllObject()
            .then(res => res.json())
            .then(response => setObjects(response));
    }, []);


    const classes = useStyles();
    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <div className={classes.root}>
                <Card style={{width: '400px'}}>
                    <CardActionArea>
                        <Link to="/addobject"
                              style={{textDecoration: 'none', color: '#000000'}}>
                            <CardContent>
                                <AddCircleOutline
                                    color="secondary" fontSize="large"/>neues Objekt
                                erstellen
                            </CardContent>
                        </Link>
                    </CardActionArea>
                </Card>
                <br/>
                <Grid container spacing={1} alignItems="center">
                    {objects.map((object, index) =>
                        <Grid item key={index}><ObjectOverviewCard object={object} key={object.id} api={props.api}/></Grid>
                    )}
                </Grid>
            </div>
        </Container>

    )
}