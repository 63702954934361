import React from "react";
import '../App.css';
import {Typography} from "@material-ui/core";

export default function ResponseRow(props) {

    const response = props.response;
    return (
        <>
            {response === -1 &&
                <Typography className={"answerNoResponseYet"}>keine Antwort</Typography>
            }
            {
                response === 0 &&
                <Typography className={"answerAbstain"}>enthalten</Typography>
            }
            {
                response === 1 &&
                <Typography className={"answerYes"}>zugestimmt</Typography>
            }
            {
                response === 2 &&
                <Typography className={"answerNo"}>abgelehnt</Typography>
            }
        </>

    );
}