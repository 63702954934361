import React, {Component} from 'react';
import {AppBar, Button, makeStyles, Toolbar, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));


class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.toggle = this.toggle.bind(this);
    }

    logout() {
        let logoutOptions = { redirectUri : '/' };
        this.props.keycloak.logout(logoutOptions);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {

        const classes = useStyles();
       return  <div>
           <AppBar position="static">
               <Toolbar>
                   <Typography variant="h6">
                       WEG Online
                   </Typography>
                   <Button color="inherit" onClick={()=>this.logout()}>Logout</Button>
               </Toolbar>
           </AppBar>
       </div>;
    }
}

export default NavBar;