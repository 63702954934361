import React, {useEffect, useState} from "react";
import '../App.css';
import ButtonAppBar from "../AppBar";
import StepWizard from "react-step-wizard";
import {Container} from "@material-ui/core";
import PresenceCheck from "./PresenceCheck";
import SittingTop from "./SittingTop";


export default function ExceuteSitting(props) {

    const [sittingId, setSittingId] = useState();
    const [objectId, setObjectId] = useState();
    //const [date, setDate] = useState('');
    //const [time, setTime] = useState('');
    const [topList, setTopList] = useState([]);
    const [owners, setOwners] = useState([]);
    const [attendees, setAttendees] = useState([]);

    const {match: {params}} = props;

    function stepChange() {

    }

    useEffect(() => {
            props.api.getSittingById(params.sittingId).then(res => res.json())
                .then(response => {
                        setSittingId(response.id);
                        //setDate(response.date.substring(0, 10));
                        //setTime(response.time);
                        setObjectId(response.objectId);

                        var topList = response.tops;
                        if (response.topssurvey) {
                            response.topssurvey.forEach(top => {
                                topList.splice(top.pos, 0, top);
                            });
                        }
                        setTopList([...topList]);
                        props.api.getOwnerByObjectId(response.objectId)
                            .then(res => res.json())
                            .then(response => {
                                setOwners(response._embedded.owner);
                                let attendeesTmp = response._embedded.owner;
                                props.api.getSittingAttendees(params.sittingId).then(res => res.json()).then(response => {
                                    for (var i = 0; i < response.length; i++) {
                                        attendeesTmp[i].attendeeId = response[i].id;
                                    }
                                })

                                setAttendees(attendeesTmp);
                            })
                    }
                );
        }
        ,
        []
    )

    return (
        <Container>
            <ButtonAppBar keycloak={props.keycloak}/>
            <Container>
                <StepWizard onStepChange={stepChange}>
                    <PresenceCheck keycloak={props.keycloak} owners={owners} setOwners={setOwners} attendees={attendees}
                                   setAttendees={setAttendees} sittingId={sittingId} api={props.api}/>
                    {topList.map((top, index) => <SittingTop key={index} index={index} top={top}
                                                             attendees={attendees} api={props.api}
                                                             sittingId={sittingId} objectId={objectId}/>)}
                </StepWizard>
            </Container>
        </Container>
    )
}