import React from 'react';
import {Button, Grid} from "@material-ui/core";
import '../App.css';
import {Edit, PictureAsPdf, PlayArrow, ViewAgendaRounded,} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";

export default function SittingRow(props) {

    const sitting = props.sitting;
    const objectId = useParams();


    var topsCount = 0;
    var topsSurveyCount = 0;

    if (sitting.tops) {
        topsCount = sitting.tops.length;
    }
    if (sitting.topssurvey) {
        topsCount += sitting.topssurvey.length;
        topsSurveyCount = sitting.topssurvey.length;
    }

    async function getPDF() {
        await props.api.getSittingPDF(sitting.id).then(response => {
            const filename = response.headers.get('Content-Disposition').split('filename=')[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            });
        })
    }

    return (<Grid container className={props.index % 2 == 0 ? "ownerRow, even" : "ownerRow, odd"}>
        <Grid item xs={3}>
            {new Intl.DateTimeFormat("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).format(Date.parse(sitting.date))} - {sitting.time}</Grid>
        <Grid item xs={2}>
            {sitting.status == 'draft' && "geplant"}
            {sitting.status == 'finished' && "durchgeführt"}
            {sitting.status == 'canceled' && "abgesagt"}
            {sitting.status == 'archived' && "archiviert"}
        </Grid>
        <Grid item xs={3}>
            {topsCount} Tops ({topsSurveyCount} Beschlüsse)
        </Grid>
        <Grid item xs={1}>
            {sitting.status == 'draft' && <Link to={`/addSitting/${objectId.objectId}?sittingId=${sitting.id}`}><Edit
                color={props.index % 2 == 0 ? "secondary" : "primary"}/></Link>}
            {sitting.status == 'finished' && <PictureAsPdf className={"iconButton"} onClick={() => getPDF()}/>}
        </Grid>
        <Grid item xs={3}>
            {sitting.status == 'draft' &&
                <Link to={`/startSitting/${sitting.id}`}><Button variant={"outlined"} size={"small"}
                                                                 color={props.index % 2 == 0 ? "secondary" : "primary"}><PlayArrow/>Versammlung
                    starten</Button></Link>}
            {sitting.status == 'finished' &&
                <Link to={`/startSitting/${sitting.id}`}><Button variant={"outlined"} size={"small"}
                                                                 color={props.index % 2 == 0 ? "secondary" : "primary"}><ViewAgendaRounded/> Versammlung
                    ansehen</Button></Link>}
        </Grid>
    </Grid>)
}