import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextareaAutosize,
    TextField,
    Typography
} from "@material-ui/core";
import {AddCircleOutline, Delete, Edit} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";

export default function TopVotingModal(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [type, setType] = useState(1);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [note, setNote] = useState('');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function handleTopVotingSubmit(event) {
        event.preventDefault();
        if (!props.top) {
            props.addTop({
                type: type,
                title: title,
                description: description,
                note: note,
                files: files,
                pos: props.index
            });
            setTitle('');
            setDescription('');
            setType(1);
            setNote('');
        } else {
            props.updateTop({
                type: type,
                title: title,
                description: description,
                note: note,
                files: files,
                pos: props.index
            }, props.index);
        }
        handleClose();
    }

    useEffect(() => {
        if (!props.top) {
            setTitle('');
            setDescription('');
            setType(1);
            setNote('');
        } else {
            setType(props.top.type);
            setTitle(props.top.title);
            setDescription(props.top.description);
            setNote(props.top.note);
            const top = props.top;
            if (top.id) {
                props.api.getTopSurveyFiles(top.id).then(fileRes => fileRes.json()).then(
                    result => {
                        result.forEach(file => {
                                if (!top.files) {
                                    top.files = [];
                                }
                                fetch(`data:${file.type};base64,${file.data}`).then(res => {
                                    res.arrayBuffer().then(buf => {
                                        const tmpFile = new File([buf], file.name, {type: file.type})
                                        top.files.push(tmpFile);
                                        setFiles(top.files);
                                    }).catch(result => console.log(result));
                                })
                            }
                        );
                    }
                ).catch()
            }
        }
    }, []);

    return (
        <div>
            {!props.top &&
                <Button variant={"outlined"} onClick={handleOpen} className={props.class}><AddCircleOutline/>Beschluss
                    TOP</Button>}
            {props.top && <Edit onClick={handleOpen} className={props.class}/>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Beschluss - TOP {props.index + 1} <Delete onClick={props.deleteTop}/>
                    </Typography>
                    <form onSubmit={handleTopVotingSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Typ</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Typ"
                                        onChange={e => setType(e.target.value)}
                                    >
                                        <MenuItem value={1}>pro Kopf</MenuItem>
                                        <MenuItem value={2}>Einheiten</MenuItem>
                                        <MenuItem value={3}>MEA</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Titel" fullWidth={true} value={title}
                                           onInput={e => setTitle(e.target.value)}
                                           required={true}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Beschreibung*</InputLabel>
                                <TextareaAutosize minRows={5} value={description} maxLength={6000}
                                                  onInput={e => setDescription(e.target.value)}
                                                  required={true} style={{width: "100%"}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Bemerkung (intern)</InputLabel>
                                <TextareaAutosize minRows={3} value={note} maxLength={6000}
                                                  onInput={e => setNote(e.target.value)} style={{width: "100%"}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <DropzoneArea initialFiles={files} showFileNames={true}
                                              dropzoneText={"Klicken oder Datei hier her ziehen"}
                                              onChange={(files) => setFiles(files)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}><Button
                                variant="contained"
                                color="secondary" onClick={handleClose}>Abbrechen</Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <Button variant="contained" color="primary" type="submit">Speichern</Button>
                                <Typography color="error">{message}</Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    )
        ;
}