import React, {Component} from 'react';
import Keycloak from 'keycloak-js';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Api from "./Api";
import NavBar from "./NavBar";
import Overview from "./Overview";
import ObjectDetails from "./object/ObjectDetails";


import AddObject from "./object/AddObject";
import AddOwner from "./object/AddOwner";
import AddCircres from "./object/AddCircres";
import AddSitting from "./object/AddSitting";
import ExceuteSitting from "./sitting/ExecuteSitting";
import Buy from "./buy/Buy";

class Secured extends Component {


    constructor(props) {
        super(props);
        this.state = {
            keycloak: null,
            authenticated: false,
            api: null
        };

    }

    async componentDidMount() {
        const keycloak = Keycloak({
            "realm": "wegonline",
            //"url": "https://umlaufbeschluss.online/auth/",
            "url": "https://weg-online.info/auth/",
            //"url": process.env.AUTH_URL,
            "ssl-required": "external",
            "resource": "wegonline",
            "public-client": true,
            "confidential-port": 0,
            "clientId": "wegonline"
        });
        await keycloak.init({onLoad: 'login-required'}).then(authenticated => {
            this.setState({keycloak: keycloak, authenticated: authenticated});
            keycloak.onTokenExpired = function() { keycloak.logout() }
        })

        this.setState({api: new Api(keycloak)});
    }

    render() {
        if (!this.state.api) {
            return <p>Loading...</p>;
        }
        const navbar = <NavBar keycloak={this.state.keycloak}/>;

        return (
            <Router>
                <Switch>
                    <Route
                        path='/'
                        exact={true}
                        render={(props) => <Overview {...props} api={this.state.api} navbar={navbar}
                                                     keycloak={this.state.keycloak}/>}
                    />
                    <Route
                        path='/object/:objectId'
                        render={(props) => <ObjectDetails {...props} api={this.state.api} navbar={navbar}
                                                          keycloak={this.state.keycloak}/>}
                    />
                    <Route
                        path='/addobject'
                        exact={true}
                        render={(props) => <AddObject {...props} api={this.state.api} keycloak={this.state.keycloak} navbar={navbar} />}
                        />
                    <Route
                        path='/addowner/:objectId'
                        exact={true}
                        render={(props) => <AddOwner {...props} api={this.state.api} keycloak={this.state.keycloak} navbar={navbar} />}
                    />
                    <Route
                        path='/addcircres/:objectId'
                        exact={true}
                        render={(props) => <AddCircres {...props} api={this.state.api} keycloak={this.state.keycloak} navbar={navbar} />}
                    />
                    <Route
                        path='/addsitting/:objectId'
                        exact={true}
                        render={(props) => <AddSitting {...props} api={this.state.api} keycloak={this.state.keycloak} navbar={navbar} />}
                    />
                    <Route
                        path='/startSitting/:sittingId'
                        exact={true}
                        render={(props) => <ExceuteSitting {...props} api={this.state.api} keycloak={this.state.keycloak} navbar={navbar} />}
                    />
                    <Route
                        path='/buy'
                        exact={true}
                        render={(props) => <Buy {...props} api={this.state.api} navbar={navbar}
                                                     keycloak={this.state.keycloak}/>}
                    />
                </Switch>
            </Router>
        )
    }

}


export default Secured;