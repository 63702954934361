import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Api from './Api';
import NavBar from './NavBar';
import Secured from "./Secured";

import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import Survey from "./Survey";
import TopVotingSurvey from "./TopVotingSurvey";

const api = new Api();

const theme = createTheme({
    palette: {
        primary: {
            main: "#1C324C"
        },
        secondary: {
            main: "#E87011"
        }
    },
});

export default function App(props) {
    const navbar = <NavBar/>;

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route
                        path='/circressurvey/'
                        render={(props) => <Survey api={api}/>}
                    />
                    <Route
                        path='/topvoting/'
                        render={(props) => <TopVotingSurvey api={api}/>}
                    />
                    <Route
                        path='/'
                        exact={true}
                        render={(props) => <Secured {...props} />}
                    />
                </Switch>
            </Router>
        </ThemeProvider>
    )

}

/**class App extends Component {

    constructor(props) {
        super(props);
       this.state = {api: new Api()};
        //this.checkAuthentication = this.checkAuthentication.bind(this);
    }

    render() {

        let {api} = this.state;
        const navbar = <NavBar/>;

        return (
            <Router>
                <Switch>
                    <Route
                        path='/circressurvey/'
                        render={(props) => <CircResSurvey {...props} api={api} navbar={navbar}/>}
                    />
                    <Route
                        path='/'
                        exact={true}
                        render={(props) => <Secured {...props} /> }
                    />
                </Switch>
            </Router>
        )
    }
};

 export default App;**/