import React, {useEffect, useState} from 'react';
import {Button, Container, Grid, IconButton, Paper, TextField, Typography} from "@material-ui/core";
import ButtonAppBar from "../AppBar";
import {Link, useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import OwnerUnits from "../owner/OwnerUnits";
import {DeleteForever} from "@material-ui/icons";
import ConfirmDialog from "../util/ConfirmDialog";

export default function AddOwner(props) {

    const history = useHistory();
    const objectId = useParams();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [units, setUnits] = useState([{unit: "", stake: ""}])

    const [ownerId, setOwnerId] = useState();
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const useStyles = makeStyles({
        link: {
            textDecoration: "none"
        }
    });

    function handleSubmit(event) {
        event.preventDefault();
    }

    function addUnit() {
        setUnits([...units, {unit: "", stake: ""}]);
    }

    function deleteUnit(index) {
        units.splice(index, 1);
        setUnits([...units]);
    };

    function handleSubmit(event) {
        event.preventDefault();
        props.api.createOwner({
            id: ownerId,
            objectId: objectId.objectId,
            firstname: firstname,
            lastname: lastname,
            email: email,
            units: units
        }).then(response => {
            setMessage('');
            if (response.ok) {
                history.push("/object/" + objectId.objectId);
            } else {
                setMessage("Fehler beim Speichern des Eigentümers (" + response.status + ")");
            }
        });
    }

    function deleteOwner()
    {
        props.api.deleteOwner(ownerId).then(() => history.push(`/object/${objectId.objectId}`));
    }

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        if (query.get("ownerId")) {
            props.api.getOwnerById(query.get("ownerId")).then(res => res.json())
                .then(response => {
                    setOwnerId(response.id);
                    setFirstName(response.firstname);
                    setLastName(response.lastname);
                    setEmail(response.email);
                    setUnits(response.units);
                });
        }


    }, []);


    return (<Container>
        <ButtonAppBar keycloak={props.keycloak}/>
        <Container>
            <Paper>
                <Typography variant="h5" color="secondary">Neuen Eigentümer anlegen  <IconButton aria-label="delete" onClick={() =>     setConfirmOpen(true)}>
                    <DeleteForever />
                </IconButton>
                    <ConfirmDialog
                        title="Eigentümer löschen?"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={deleteOwner}
                    >
                        Eigentümer wirklich löschen?
                    </ConfirmDialog></Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label="Vorname" fullWidth={false} value={firstname}
                                               onInput={e => setFirstName(e.target.value)}
                                               required={true}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Nachname" fullWidth={false} value={lastname}
                                               onInput={e => setLastName(e.target.value)}
                                               required={true}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="eMail" fullWidth={false} value={email}
                                               onInput={e => setEmail(e.target.value)}
                                               required={true} type="email"/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    Einheiten
                                </Grid>
                                <Grid item>
                                    {units.map((unit, index) => <OwnerUnits unit={unit} index={index} key={index}
                                                                            deleteUnit={() => deleteUnit(index)}/>)}
                                    <Button color={"secondary"} onClick={() => addUnit()}>weitere Einheit
                                        hinzufügen</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Link to={"/object/" + objectId.objectId}
                                  style={{textDecoration: 'none', color: '#000000'}}><Button
                                variant="contained"
                                color="secondary">Abbrechen</Button></Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" type="submit">Speichern</Button>
                            <Typography color="error">{message}</Typography>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    </Container>);
}